
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/dao/[address]/[[...slug]]",
      function () {
        return require("private-next-pages/dao/[address]/[[...slug]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/dao/[address]/[[...slug]]"])
      });
    }
  